@import 'styles/includes';

.ProductList {
    $root: &;
    background-color: $colorLichen;
    color: $colorBlue;

    &--Gradient {
        background: $colorGradient;

        @include media(m) {
            background-color: $colorLichen;
        }
    }

    &--Blue {
        background: $colorBlue;
        color: $colorWhite;

        @include media(m) {
            background-color: $colorBlue;
        }
    }

    &__Container {
        @extend %container;

        @include media(ml) {
            display: flex;
        }
    }

    &__ImageContainer {
        padding-bottom: 86%;
        position: relative;

        @include media(m) {
            padding-bottom: 52.6%;
        }

        @include media(ml) {
            padding-bottom: 0;
            width: 50%;
        }
    }

    &__Image {
        width: calc(100% + 3.2rem);
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        position: absolute;
        left: -1.6rem;
        right: -1.6rem;

        @include media(ml) {
            width: 50vw;
            height: 100%;
            position: absolute;
            right: 0;
            left: auto;
        }
    }

    &__Content {
        padding: 40px 0;

        #{$root}--Gradient & {
            @include media(ml) {
                background: linear-gradient(
                    45.79deg,
                    #fff8c4 2.97%,
                    transparent 45.52%,
                    transparent 94.77%
                );
            }
        }

        @include media(ml) {
            padding: 40px 0 40px 20px;
            width: 50%;
        }

        @include media(ls) {
            padding: 4.5rem 0 4.5rem 5.5rem;
        }
    }

    &__Title {
        @extend %h3;
        margin-bottom: 15px;

        b {
            @extend %curved-border;
        }
    }

    &__List {
        @include media(ls) {
            margin: 0 -4rem;
        }
    }
}

.CardItem {
    padding: 1.5rem;
    transition:
        background-color $transition,
        box-shadow $transition;
    border-radius: 0.8rem;
    display: flex;
    position: relative;

    .ProductList--Blue & {
        max-width: 54.8rem;
    }

    &__Icon {
        margin-right: 10px;
        max-width: 5.5rem;
        width: 100%;

        @include media(ml) {
            margin-right: 30px;
        }

        @include media(l) {
            margin-right: 48px;
        }
    }

    &__RightIcon {
        opacity: 0;
        margin-left: 2.4rem;
        margin-top: auto;
        margin-bottom: auto;
        flex-shrink: 0;
    }

    @include media(ml) {
        padding: 2rem 4rem;
    }

    &:hover {
        background-color: $colorWhite;

        @include media(ml) {
            box-shadow: 0px 0px 0px 0.1rem rgba(0, 91, 117, 1);
        }

        .ProductList--Blue & {
            color: $colorBlue;
        }

        .CardItem__RightIcon {
            opacity: 1;
        }

        .CardItem__LinkIcon {
            transform: translateX(10px);
        }
    }

    &__Link {
        position: absolute;
        inset: 0;
        z-index: 1;
    }

    &__Title {
        @extend %h4;
        margin-bottom: 3px;
        color: currentColor;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    &__LinkIcon {
        margin-left: 15px;
        transition: transform $transition;

        &--Right {
            min-width: 18px;
            margin-top: auto;
            margin-bottom: auto;
            margin-left: 24px;
        }
    }

    &__Text {
        @extend %p;
        color: $colorContrast;

        .ProductList--Blue .CardItem:not(:hover) & {
            color: $colorWhite;
        }

        .ProductList--Blue & {
            @include media(ml) {
                max-width: 42.3rem;
            }
        }
    }
}
